var PJAX = function () {

	var $barbaWrapper = $('[data-barba="wrapper"]');

	if (!$barbaWrapper.length) {
		return;
	}

	barba.init({

		transitions: [
			PJAXTransitionGeneral,
			PJAXTransitionMasonryGrid,
			PJAXTransitionFullscreenSlider,
			PJAXTransitionHalfscreenSlider,
			PJAXTransitionOverlayMenu,
			PJAXTransitionNavProjects,
		]

	});


}
