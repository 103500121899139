var SectionContent = function ($scope) {

	var $target = $scope.find('.section-content[data-os-animation]');

	if (!$target.length) {

		return;

	}

	$target.each(function () {

		var
			tl = new TimelineMax(),
			$current = $(this),
			$headline = $current.find('.section__headline'),
			$headlineProperty = $current.find('.figure-property__headline'),
			$heading = $current.find('h2'),
			$subheading = $current.find('h3'),
			$property = $current.find('.figure-property');

		prepare();
		animate();

		function prepare() {

			hideWords($heading, 0.6, 0.02, '50px');

			TweenMax.set($headlineProperty, {
				scaleX: 0,
				transformOrigin: 'left center'
			});

			TweenMax.set($headline, {
				scaleX: 0,
				transformOrigin: 'left center'
			});

		}

		function animate() {

			if ($heading.length) {

				tl.add(animateWords($heading));

			}
			if ($headline.length) {

				tl.to($headline, 1.2, {
					scaleX: 1,
					ease: Expo.easeInOut
				}, '0');

			}

			if ($subheading.length) {

				tl.add(animateLines($subheading, 1.2, 0.01), '-=0.8')

			}

			if ($property.length) {

				$property.each(function () {
					tl.add(animateLines($(this), 1.2, 0.01), '-=1.8');
					tl.to($(this).find($headlineProperty), 1.2, {
						scaleX: 1,
						ease: Expo.easeInOut,
						transformOrigin: 'left center'
					}, '0');
				});

			}

			createOSScene($current, tl);

		}

	})

}
