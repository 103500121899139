var ProjectBackgrounds = function () {

	if (!window.$backgrounds.length) {
		return;
	}

	$document
		.on('mouseenter touchstart', '.header a[data-post-id]', function () {
			var
				postId = $(this).data('post-id'),
				$targetBackground = window.$backgrounds.filter('[data-background-for="' + postId + '"]');

			if (!$targetBackground.length) {
				return;
			}

			window.$backgrounds.filter('active').removeClass('active');
			$targetBackground.addClass('active');

		})
		.on('mouseleave touchend', '.header a[data-post-id]', function () {

			window.$backgrounds.filter('.active').removeClass('active');

		})
		.on('click', 'a[data-post-id]', function () {

			var
				postId = $(this).data('post-id'),
				$targetBackground = window.$backgrounds.filter('[data-background-for="' + postId + '"]');

			if (!$targetBackground.length) {
				return;
			}

			window.$backgrounds.filter('active').removeClass('active');
			$targetBackground.addClass('selected');

		});

}
