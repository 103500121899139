var SectionMasthead = function ($scope) {

	var $target = $scope.find('.section-masthead[data-os-animation]');

	if (!$target.length) {

		return;

	}

	var
		$headline = $target.find('.section__headline'),
		$heading = $target.find('h1'),
		$subheading = $target.find('h2'),
		$properties = $target.find('.figure-property');

	prepare();
	animate();

	function prepare() {

		TweenMax.set($headline, {
			scaleX: 0,
			transformOrigin: 'left center'
		});

		hideWords($heading, 0, 0);

	}

	function animate() {

		var tl = new TimelineMax();

		// if ($headline.length) {

		tl.to($headline, 1.2, {
			scaleX: 1,
			ease: Expo.easeInOut
		}, '0');

		// }

		// if ($heading.length) {
		tl.add(animateWords($heading, 1.2, 0.03, true), '0');
		// }

		// if ($subheading.length) {
		tl.add(animateLines($subheading, 1.2, 0.01), '0.6');
		// }

		// if ($properties.length) {
		tl.add(animateLines($properties, 1.2, 0.02), '0.6');
		// }

		// if ($overlay.length) {
		// 	tl.to($overlay, 1.2, {
		// 		opacity: initialOpacity,
		// 		ease: Expo.easeInOut
		// 	}, '0');
		// }

		createOSScene($target, tl);

	}

}
