function createOSScene($el, tl, $customTrigger, noReveal) {

	var
		$trigger = $el,
		masterTL = new TimelineMax();

	if ($customTrigger && $customTrigger.length) {
		$trigger = $customTrigger;
	}

	if (!noReveal) {
		// reveal hidden element first
		masterTL.add(TweenMax.set($el, {
			autoAlpha: 1
		}));
	}

	masterTL.add(tl);

	masterTL.add(function () {
		$el.attr('data-os-animation', 'animated');
	});

	var scene = new $.ScrollMagic.Scene({
			triggerElement: $trigger,
			triggerHook: window.SMSceneTriggerHook,
			reverse: window.SMSceneReverse
		})
		.setTween(masterTL)
		.addTo(window.SMController);


	// update scene when smooth scroll is enabled
	if (window.SB !== undefined) {

		window.SB.addListener(function () {
			scene.refresh();
		});

	}

}
