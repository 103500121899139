function Section(data) {

	return new Promise(function (resolve, reject) {

		let scene;
		// Init ScrollMagic
		let controller = new ScrollMagic.Controller();

		// Run through all sections
		let items = document.querySelectorAll(".js--section");
		let header = document.querySelector('.js--header');

		let triggerHook = parseInt(header.clientHeight * 100 / $(window).height()) / 100;

		items.forEach(function(element, index) {
			let sectionName = element.getAttribute('data-section-name');
			let height = element.clientHeight; //height of current element
			let scene = new ScrollMagic.Scene({
				duration: height,
				triggerElement: element,
				triggerHook: triggerHook,
				reverse: true
			})
			.on("enter", function(e) {

				if ( element.classList.contains('is--header_dark') ) {
					header.classList.remove("header_white");
					header.classList.add("header_dark");
				} else {
					header.classList.remove("header_dark");
					header.classList.add("header_white");
				}

				// console.log({
				// 	'sectionName': sectionName,
				// 	'event': e.type,
				// 	'element.header_white': element.classList.contains('is--header_white'),
				// 	'element.header_dark': element.classList.contains('is--header_dark'),
				// 	'header.header_white': header.classList.contains('header_white'),
				// 	'header.header_dark': header.classList.contains('header_dark')
				// });

			})
			//.addIndicators() // add indicators for debug (requires plugin)
			.addTo(controller);
		});



		let sectionHeader = document.querySelector(".js--section-header");

		let headerHeight = sectionHeader.clientHeight; //height of current element
		let headerScene = new ScrollMagic.Scene({
			duration: headerHeight,
			triggerElement: sectionHeader,
			triggerHook: triggerHook,
			reverse: true
		})
			.on("enter", function(e) {

				if ( sectionHeader.classList.contains('is--header_dark') ) {
					header.classList.remove("header_white");
					header.classList.add("header_dark");
				} else {
					header.classList.remove("header_dark");
					header.classList.add("header_white");
				}

				// console.log({
				// 	'sectionName': 'section header',
				// 	'event': e.type,
				// 	'enter sectionHeader.header_white': sectionHeader.classList.contains('is--header_white'),
				// 	'enter sectionHeader.header_dark': sectionHeader.classList.contains('is--header_dark'),
				// 	'enter header.header_white': header.classList.contains('header_white'),
				// 	'enter header.header_dark': header.classList.contains('header_dark')
				// });

			})
			//.addIndicators() // add indicators for debug (requires plugin)
			.addTo(controller);

	});

}
