function lockScroll(enabled) {

	var
		$window = $(window),
		lastTop = $window.scrollTop();

	if (enabled === true) {

		TweenMax.set(window.$body, {
			className: '+=body_lock-scroll',
			top: -lastTop
		});

	} else {

		var
			offset = parseInt(window.$body.css('top'), 10),
			offsetValue = Math.abs(offset);

		TweenMax.set(window.$body, {
			className: '-=body_lock-scroll',
			top: ''
		});

		$window.scrollTop(offsetValue);

	}

}
