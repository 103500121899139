var PJAXTransitionGeneral = {

	before() {
		window.InteractiveCursor.drawLoading();
	},

	beforeLeave: (data) => {

		return new Promise(function (resolve, reject) {

			PJAXPrepareTransition(data).then(function () {
				resolve(true)
			});

		});

	},

	leave: (data) => {

		return new Promise(function (resolve, reject) {

			var
				tl = new TimelineMax(),
				$currentContainer = $(data.current.container);

			tl.timeScale(1.5);

			if (!$overlay.hasClass('opened')) {

				tl.to($currentContainer, 1.2, {
					x: '10vw',
					force3D: true,
					transformOrigin: 'left center',
					ease: Expo.easeInOut
				});

				tl
					.to($curtains, 0.6, {
						scaleX: 1,
						transformOrigin: 'left center',
						ease: Expo.easeInOut
					}, '0.2');

			}

			tl
				.add(function () {
					window.$header.removeClass('header_black').addClass('header_white');
				}, '0')
				.add(function () {
					$currentContainer.remove();
					resolve(true);
				});

		});

	},

	enter: (data) => {

		return new Promise(function (resolve, reject) {

			PJAXInitNewPage(data).then(function () {
				resolve(true)
			});

		});

	},

	afterEnter: (data) => {

		return new Promise(function (resolve, reject) {

			var
				tl = new TimelineMax(),
				$nextContainer = $(data.next.container),
				$nextMasthead = $nextContainer.find('.section-masthead').first(),
				$nextBg = $nextMasthead.find('.section-masthead__background .art-parallax__bg');

			tl
				.set($burger, {
					className: '-=header__burger_opened'
				})
				.set($nextContainer, {
					autoAlpha: 1,
					x: '-5vw',
					force3D: true,
					transformOrigin: 'right center',
				});

			// animate (close) header if it's opened
			if (window.$pageHeader.hasClass('opened')) {

				var tlClose = $nextBg.length ? window.PageHeader.hideOverlayMenu(false) : window.PageHeader.hideOverlayMenu(true);

				tl
					.add(tlClose, '0')
					.set(window.$overlay, {
						className: '+=intransition'
					})
					.to($nextContainer, 1.2, {
						x: '0vw',
						force3D: true,
						ease: Expo.easeInOut
					});

			} else {

				tl
					.to($nextContainer, 1.2, {
						x: '0vw',
						force3D: true,
						ease: Expo.easeInOut
					})
					.to(window.$curtains, 0.6, {
						scaleX: 0,
						transformOrigin: 'right center',
						ease: Expo.easeInOut
					}, '0.3');

			}

			tl
				.set(window.$overlay, {
					className: '+=intransition'
				})
				.add(function () {
					resolve(true);
				}, '-=0.3');


		});

	},

	after: (data) => {

		return new Promise(function (resolve, reject) {
			PJAXFinishLoading(data).then(function () {
				resolve(true);
			});

		});

	}

}
